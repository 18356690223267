import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ViewInstallmentComponent } from '../view-installment/view-installment.component';
import { Big } from 'big.js';
import { ServiceService } from 'src/app/service/service.service';
import { Router } from '@angular/router';
import { PopupComfirmComponent } from '../popup-comfirm/popup-comfirm.component';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.css']
})
export class AddNewComponent implements OnInit {
  @Input() page: string;
  @Input() item: any[] = [];

  radioTypeList: any = [];
  form: FormGroup;
  formRadio: FormGroup;
  listDay: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  underwriters: any[] = ['1'];
  name: string;
  listData: any;
  installment: any[] = [];
  list: any[] = [];
  listNumber: any = [
    {
      id: 1,
      name: 'จ่ายเฉพาะดอก'
    }, {
      id: 2,
      name: 'ลดต้นลดดอก'
    }
  ];
  decimalLength = 6;
  installmentTemp: any[] = [];
  paymentDateSpecific: string;

  paymentDateSpecificTemp: any = [];
  maxOpen: string;
  file: string = '';
  files: File;
  imageUrl: string;
  cfSave: Boolean = false;
  constructor(private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private service: ServiceService,
    private router: Router) { }

  ngOnInit() {
    this.initialFrom();
    this.listApi();
    this.radioPayBy();
    this.radioType(1);
    if (this.page === 'Edit') {
      this.getDataApi();
    }
  }


  listApi() {
    this.listData = {
      "interestType": [
        {
          "interestTypeId": 1,
          "interestTypeDesc": "รายปี",
          "seqNo": 1
        },
        {
          "interestTypeId": 2,
          "interestTypeDesc": "รายเดือน",
          "seqNo": 2
        }
      ],
      "paymentType": [
        {
          "paymentTypeId": 1,
          "paymentTypeDesc": "รายวัน",
          "seqNo": 1
        },
        {
          "paymentTypeId": 2,
          "paymentTypeDesc": "รายเดือน",
          "seqNo": 2
        },
        {
          "paymentTypeId": 3,
          "paymentTypeDesc": "ระบุวัน",
          "seqNo": 3
        }
      ],
      "transactionType": [
        {
          "transactionTypeId": 1,
          "transactionTypeDesc": "ทอง",
          "seqNo": 1,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 2,
          "transactionTypeDesc": "ทุน",
          "seqNo": 2,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 3,
          "transactionTypeDesc": "ออม",
          "seqNo": 3,
          "coler": '#3d5ff5'
        },
        {
          "transactionTypeId": 4,
          "transactionTypeDesc": "ความงาม",
          "seqNo": 4,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 5,
          "transactionTypeDesc": "เบอร์",
          "seqNo": 5,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 6,
          "transactionTypeDesc": "เพชร",
          "seqNo": 6,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 7,
          "transactionTypeDesc": "กู้",
          "seqNo": 7,
          "coler": '#ec8b1c'
        }
      ]
    }
  }

  radioType(event) {
    this.form.controls.transactionType.setValue(event);
    if (event === 7) {
      this.form.controls.interestType.setValue('1');
      this.form.controls.interestRate.setValue('0.00');
      this.form.controls.interestRate.enable();
      this.form.controls.interestType.enable();
      this.form.controls.firstDownAmt.setValue(null);
      this.form.controls.interestRate1.setValue(null);
      this.form.controls.interestRate1.disable();
      this.form.controls.firstDownAmt.disable();
    } else {
      if (event === 3) {
        this.form.controls.firstDownAmt.setValue(null);
        this.form.controls.interestRate1.setValue(null);
        this.form.controls.interestRate1.disable();
        this.form.controls.firstDownAmt.disable();
      } else {
        this.form.controls.interestRate1.setValue('0.00');
        this.form.controls.interestRate1.enable();
        this.form.controls.firstDownAmt.enable();
      }
      this.form.controls.interestRate.setValue(null);
      this.form.controls.interestType.setValue(null);
      this.form.controls.interestRate.disable();
      this.form.controls.interestType.disable();
    }
  }

  initialFrom() {
    this.form = new FormGroup({
      customerName: new FormControl(null),
      description: new FormControl(''),
      transactionType: new FormControl(''),
      createDate: new FormControl(''),
      interestRate: new FormControl(''),
      interestType: new FormControl(''),
      interestRate1: new FormControl(''),
      paymentType: new FormControl(''),
      daily: new FormControl(''),
      paymentDate: new FormControl(''),
      daily1: new FormControl(''),
      daily2: new FormControl(''),
      daily3: new FormControl(''),
      daily4: new FormControl(''),
      firstDueDate: new FormControl(''),
      principle: new FormControl('', Validators.pattern(/^[0-9]/)),
      total: new FormControl(''),
      contractPeriod: new FormControl('', Validators.pattern(/^[0-9]/)),
      firstDownAmt: new FormControl(''),
      remaining: new FormControl(''),
      installmentAmt: new FormControl(''),
      income: new FormControl(''),
      remark: new FormControl('')
    });
    this.form.controls.paymentType.setValue('1');
    this.form.controls.createDate.patchValue(this.formatDateInput(new Date()));
    // let date = new Date();
    // date.setDate(date.getDate() + 1);
    // this.form.controls.firstDueDate.patchValue(this.formatDateInput(date));
    this.form.controls.contractPeriod.setValue(1);
    // this.form.controls.principle.valueChanges.subscribe(() =>
    //   this.form.controls.principle.setValidators([Validators.pattern(/[0-9]/)]));
  }

  formatDateInput(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  formatDateInput2(date: Date) {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  formatDateInput3(date: Date) {
    return moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
  }

  formatDateInput4(date: Date) {
    return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }

  formatDateInput5(date: Date) {
    return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }


  radioPayBy() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    this.maxOpen = '';
    if (this.form.controls.paymentType.value === '1') {
      this.form.controls.paymentDate.setValue('');
      this.form.controls.daily1.setValue('');
      this.form.controls.daily2.setValue('');
      this.form.controls.daily3.setValue('');
      this.form.controls.daily4.setValue('');
      this.form.controls.daily.enable();
      this.form.controls.paymentDate.disable();
      this.form.controls.daily1.disable();
      this.form.controls.daily2.disable();
      this.form.controls.daily3.disable();
      this.form.controls.daily4.disable();
      this.form.controls.firstDueDate.patchValue(this.formatDateInput(date));
    } else if (this.form.controls.paymentType.value === '2') {
      this.form.controls.daily.setValue('');
      this.form.controls.daily1.setValue('');
      this.form.controls.daily2.setValue('');
      this.form.controls.daily3.setValue('');
      this.form.controls.daily4.setValue('');
      this.form.controls.daily.disable();
      this.form.controls.paymentDate.enable();
      this.form.controls.daily1.disable();
      this.form.controls.daily2.disable();
      this.form.controls.daily3.disable();
      this.form.controls.daily4.disable();
      date.setMonth(date.getMonth() + 1);
      const maxDate = moment(date, 'DDMMYYYY HH:mm:ss');
      this.maxOpen = this.formatDateInput5(new Date(maxDate.year(), maxDate.month(), maxDate.date()));
      this.form.controls.firstDueDate.patchValue(this.formatDateInput(date));
    } else {
      this.form.controls.daily.setValue('');
      this.form.controls.paymentDate.setValue('');
      this.form.controls.daily.disable();
      this.form.controls.paymentDate.disable();
      this.form.controls.daily1.enable();
      this.form.controls.daily2.enable();
      this.form.controls.daily3.enable();
      this.form.controls.daily4.enable();
      this.form.controls.firstDueDate.patchValue(this.formatDateInput(date));
    }

  }

  public close() {
    this.activeModal.close(false);
  }

  async save() {
    this.form.controls.customerName.value ? this.form.controls.customerName.setErrors(null) : this.form.controls.customerName.setErrors({ 'incorrect': true });
    this.form.controls.description.value ? this.form.controls.description.setErrors(null) : this.form.controls.description.setErrors({ 'incorrect': true });
    this.form.controls.principle.value ? this.form.controls.principle.setErrors(null) : this.form.controls.principle.setErrors({ 'incorrect': true });
    this.form.controls.income.value ? this.form.controls.income.setErrors(null) : this.form.controls.income.setErrors({ 'incorrect': true });

    if (this.form.controls.transactionType.value === 1 || this.form.controls.transactionType.value === 2 || this.form.controls.transactionType.value === 4 ||
      this.form.controls.transactionType.value === 5 || this.form.controls.transactionType.value === 6) {
      this.form.controls.interestRate1.value ? this.form.controls.interestRate1.setErrors(null) : this.form.controls.interestRate1.setErrors({ 'incorrect': true });
    } else if (this.form.controls.transactionType.value === 7) {
      this.form.controls.interestRate.value ? this.form.controls.interestRate.setErrors(null) : this.form.controls.interestRate.setErrors({ 'incorrect': true });
    }

    if (this.form.controls.transactionType.value === 1 || this.form.controls.transactionType.value === 6) {
      this.form.controls.firstDownAmt.value ? this.form.controls.firstDownAmt.setErrors(null) : this.form.controls.firstDownAmt.setErrors({ 'incorrect': true });
    }

    if (this.form.controls.paymentType.value === '1') {
      this.form.controls.daily.value ? this.form.controls.daily.setErrors(null) : this.form.controls.daily.setErrors({ 'incorrect': true });
    } else if (this.form.controls.paymentType.value === '2') {
      this.form.controls.paymentDate.value ? this.form.controls.paymentDate.setErrors(null) : this.form.controls.paymentDate.setErrors({ 'incorrect': true });
    } else {
      this.form.controls.daily1.value ? this.form.controls.daily1.setErrors(null) : this.form.controls.daily1.setErrors({ 'incorrect': true });
    }

    if (this.form.controls.customerName.errors ||
      this.form.controls.description.errors ||
      this.form.controls.principle.errors ||
      this.form.controls.income.errors ||
      this.form.controls.interestRate1.errors ||
      this.form.controls.interestRate.errors ||
      this.form.controls.daily.errors ||
      this.form.controls.paymentDate.errors ||
      this.form.controls.daily1.errors ||
      this.form.controls.firstDownAmt.errors) {
      return;
    }

    if(new Date(this.form.controls.createDate.value) > new Date(this.form.controls.firstDueDate.value)) {
      await this.onPopup('วันที่เริ่มผ่อน ต้องไม่น้อยกว่า วันที่สร้าง', false, '');
      return;
    }


    const cf = await this.onPopup('ยืนยัน', true, '');
    if (cf) {
      this.installmentTemp = [];
      this.installment.forEach(item => {
        let installment = {
          'installmentNo': item.installmentNo,
          'dueDate': this.formatDateInput3(item.dueDate),
          'installmentAmt': parseFloat(this.formatNumber(item.installmentAmt))
        };
        this.installmentTemp.push(installment);
      });
      this.paymentDateSpecific = '';
      this.form.controls.daily1.value ? this.paymentDateSpecific += this.form.controls.daily1.value : null;
      this.form.controls.daily2.value ? this.paymentDateSpecific += ',' + this.form.controls.daily2.value : null;
      this.form.controls.daily3.value ? this.paymentDateSpecific += ',' + this.form.controls.daily3.value : null;
      this.form.controls.daily4.value ? this.paymentDateSpecific += ',' + this.form.controls.daily4.value : null;

      if (this.form.controls.transactionType.value === 3) {
        await this.uploadFile();
      }
      this.cfSave = false;
      return new Promise<any>((resolve, reject) => {
        this.service.save(this.getData()).subscribe(async resp => {
          if (resp && resp.statusCode === 200) {

            let popup = await this.onPopup('สำเร็จ', false, '');
            if (!popup) {
              this.cfSave = true;
              // this.close();
            }
          } else {
            await this.onPopup(resp.message, false, '');
          }
          resolve(true);
        },
          async (errorRes) => {
            if (errorRes.error && errorRes.error.statusCode === 500) {
              await this.onPopup(errorRes.error.message, false, '');
              return;
            }
          });
      });
    }
  }

  getData() {
    let data = {
      'transactionType': parseInt(this.form.controls.transactionType.value), // type transactionType
      'customerName': this.form.controls.customerName.value ? this.form.controls.customerName.value.split(/\n/).filter(element => element) : '', // ชื่อลูกค้า
      'description': this.form.controls.description.value, // รายการ
      'interestType': this.form.controls.interestType.value ? parseInt(this.form.controls.interestType.value) : 0,
      'interestRate': this.form.controls.interestType.value ?
        this.form.controls.interestRate.value ? parseInt(this.form.controls.interestRate.value) : 0 :
        this.form.controls.interestRate1.value ? parseInt(this.form.controls.interestRate1.value) : 0,
      'paymentType': this.form.controls.paymentType.value ? parseInt(this.formatNumber(this.form.controls.paymentType.value)) : 0,
      'paymentDate': this.form.controls.paymentDate.value ? parseInt(this.form.controls.paymentDate.value) : 0,
      'daily': this.form.controls.daily.value ? parseInt(this.form.controls.daily.value) : 0,
      'remark': this.form.controls.remark.value,
      'principle': this.form.controls.principle.value ? parseInt(this.formatNumber(this.form.controls.principle.value)) : 0,
      'total': this.form.controls.total.value ? parseInt(this.formatNumber(this.form.controls.total.value)) : 0,
      'contractPeriod': parseInt(this.form.controls.contractPeriod.value),
      'firstDownAmt': this.form.controls.firstDownAmt.value ? parseInt(this.formatNumber(this.form.controls.firstDownAmt.value)) : 0,
      'remaining': parseInt(this.formatNumber(this.form.controls.remaining.value)),
      'installmentAmt': parseInt(this.formatNumber(this.form.controls.installmentAmt.value)),
      'income': parseInt(this.formatNumber(this.form.controls.income.value)),
      'firstDueDate': this.formatDateInput4(this.form.controls.firstDueDate.value),
      'createDate': this.formatDateInput4(this.form.controls.createDate.value),
      'paymentDateSpecific': this.paymentDateSpecific,
      // 'contractEndDate': '24/04/2023',
      'installment': this.installmentTemp,
      'imageUrl': this.imageUrl
    }
    return data;
  }

  change() {
    this.installment = [];
    if (this.form.controls.principle.value) {
      this.form.controls.principle.setValue(this.localeString(parseFloat(this.formatNumber(this.form.controls.principle.value))));
    }
    if (this.form.controls.interestRate.value) {
      this.form.controls.interestRate.setValue(parseFloat(this.form.controls.interestRate.value).toFixed(2));
    } else {
      if (this.form.controls.interestRate.enabled) {
        this.form.controls.interestRate.setValue('0.00');
      }
    }
    if (this.form.controls.interestRate1.value) {
      this.form.controls.interestRate1.setValue(parseFloat(this.form.controls.interestRate1.value).toFixed(2));
    } else {
      if (this.form.controls.interestRate1.enabled) {
        this.form.controls.interestRate1.setValue('0.00');
      }
    }
    this.form.controls.income.setValue(this.form.controls.income.value ? this.localeString(parseInt(this.formatNumber(this.form.controls.income.value))) : null);
    this.form.controls.contractPeriod.setValue(this.form.controls.contractPeriod.value ? this.form.controls.contractPeriod.value : '1');
    this.form.controls.firstDownAmt.setValue(this.form.controls.firstDownAmt.value ? this.localeString(parseFloat(this.formatNumber(this.form.controls.firstDownAmt.value))) : '');
    this.form.controls.total.setValue(null);
    this.form.controls.remaining.setValue(null);
    this.form.controls.installmentAmt.setValue(null);
    let date10 = new Date();
    let date11 = new Date();
    let date = new Date(this.form.controls.firstDueDate.value);
    let sum = null;
    let sumDate = 0;

    this.paymentDateSpecificTemp = [];
    this.form.controls.daily1.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily1.value, flag: false }) : null;
    this.form.controls.daily2.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily2.value, flag: false }) : null;
    this.form.controls.daily3.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily3.value, flag: false }) : null;
    this.form.controls.daily4.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily4.value, flag: false }) : null;

    if (this.form.controls.transactionType.value === 1 || this.form.controls.transactionType.value === 6) {
      if (this.form.controls.principle.value && this.form.controls.interestRate1.value
        && (this.form.controls.daily.value || this.form.controls.paymentDate.value
          || this.form.controls.daily1.value || this.form.controls.daily2.value
          || this.form.controls.daily3.value || this.form.controls.daily4.value
          || this.form.controls.firstDownAmt.value)) {

        let principle = parseFloat(this.formatNumber(this.form.controls.principle.value)) - (this.form.controls.firstDownAmt.value ? parseFloat(this.formatNumber(this.form.controls.firstDownAmt.value)) : 0);
        let installmentAmt = Math.round(principle * parseFloat(this.form.controls.interestRate1.value) / 100) + principle;

        this.form.controls.total.setValue(this.localeString(Math.round(installmentAmt)));
        this.form.controls.remaining.setValue(this.localeString(parseFloat(this.formatNumber(this.form.controls.principle.value)) - (this.form.controls.firstDownAmt.value ? parseFloat(this.formatNumber(this.form.controls.firstDownAmt.value)) : 0)));
        this.form.controls.installmentAmt.setValue(this.localeString(Math.round(parseInt(this.formatNumber(this.form.controls.total.value)) / parseInt(this.form.controls.contractPeriod.value))));
        this.form.controls.income.setValue(this.localeString(installmentAmt - principle));

        for (let index = 1; index <= parseFloat(this.form.controls.contractPeriod.value); index++) {
          date = this.checkDate(date, sumDate, index, date10, date11);
          let installment = {
            'installmentNo': index,
            'dueDate': this.formatDateInput2(date),
            'installmentAmt': this.localeString(this.form.controls.installmentAmt.value)
          };
          this.installment.push(installment);

          if (this.form.controls.paymentType.value === '3') {
            date = this.updateDtae(date, date11);
          }
        }
      }

    } else if (this.form.controls.transactionType.value === 2 || this.form.controls.transactionType.value === 4 || this.form.controls.transactionType.value === 5) {
      if (this.form.controls.principle.value && this.form.controls.interestRate1.value
        && (this.form.controls.daily.value || this.form.controls.paymentDate.value
          || this.form.controls.daily1.value || this.form.controls.daily2.value
          || this.form.controls.daily3.value || this.form.controls.daily4.value
          || this.form.controls.firstDownAmt.value)) {

        let principle = parseFloat(this.formatNumber(this.form.controls.principle.value));
        let installmentAmt = Math.round(principle * parseFloat(this.form.controls.interestRate1.value) / 100) + (principle - (this.form.controls.firstDownAmt.value ? parseFloat(this.formatNumber(this.form.controls.firstDownAmt.value)) : 0));

        this.form.controls.total.setValue(this.localeString(Math.round(installmentAmt)));
        this.form.controls.remaining.setValue(this.localeString(Math.round(installmentAmt)));
        this.form.controls.installmentAmt.setValue(this.localeString(Math.round(parseInt(this.formatNumber(this.form.controls.remaining.value)) / parseInt(this.form.controls.contractPeriod.value))));
        this.form.controls.income.setValue(this.localeString((installmentAmt + (this.form.controls.firstDownAmt.value ? parseFloat(this.formatNumber(this.form.controls.firstDownAmt.value)) : 0)) - principle));

        for (let index = 1; index <= parseFloat(this.form.controls.contractPeriod.value); index++) {
          date = this.checkDate(date, sumDate, index, date10, date11);
          let installment = {
            'installmentNo': index,
            'dueDate': this.formatDateInput2(date),
            'installmentAmt': this.localeString(this.form.controls.installmentAmt.value)
          };
          this.installment.push(installment);

          if (this.form.controls.paymentType.value === '3') {
            date = this.updateDtae(date, date11);
          }
        }
      }
    } else if (this.form.controls.transactionType.value === 3) {
      if (this.form.controls.principle.value && (this.form.controls.daily.value || this.form.controls.paymentDate.value
        || this.form.controls.daily1.value || this.form.controls.daily2.value
        || this.form.controls.daily3.value || this.form.controls.daily4.value)) {
        this.form.controls.total.setValue(this.localeString(this.form.controls.principle.value));
        this.form.controls.remaining.setValue(this.localeString(this.form.controls.principle.value));
        this.form.controls.installmentAmt.setValue(this.localeString(Math.round(parseInt(this.formatNumber(this.form.controls.remaining.value)) / parseInt(this.form.controls.contractPeriod.value))));
        for (let index = 1; index <= parseFloat(this.form.controls.contractPeriod.value); index++) {
          date = this.checkDate(date, sumDate, index, date10, date11);
          let installment = {
            'installmentNo': index,
            'dueDate': this.formatDateInput2(date),
            'installmentAmt': this.localeString(this.form.controls.installmentAmt.value)
          };
          this.installment.push(installment);

          if (this.form.controls.paymentType.value === '3') {
            date = this.updateDtae(date, date11);
          }
        }
      }
    }

    else if (this.form.controls.transactionType.value === 7) {
      this.form.controls.interestRate1.setValue(null);
      if (parseInt(this.form.controls.interestType.value) === 1) {
        if (this.form.controls.principle.value && this.form.controls.contractPeriod.value
          && this.form.controls.interestRate.value
          && (this.form.controls.daily.value || this.form.controls.paymentDate.value
            || this.form.controls.daily1.value || this.form.controls.daily2.value
            || this.form.controls.daily3.value || this.form.controls.daily4.value)) {
          this.form.controls.interestRate1.setValue(parseFloat(this.form.controls.interestRate.value) * parseFloat(this.form.controls.contractPeriod.value));
          let installmentAmt = Math.round(parseFloat(this.formatNumber(this.form.controls.principle.value)) * parseFloat(this.form.controls.interestRate1.value) / 100) + parseFloat(this.formatNumber(this.form.controls.principle.value));
          this.form.controls.total.setValue(this.localeString(Math.round(installmentAmt)));
          this.form.controls.remaining.setValue(this.localeString(Math.round(installmentAmt)));
          this.form.controls.installmentAmt.setValue(this.localeString((installmentAmt - parseFloat(this.formatNumber(this.form.controls.principle.value))) / parseFloat(this.form.controls.contractPeriod.value)));
          this.form.controls.income.setValue(this.localeString(installmentAmt - parseFloat(this.formatNumber(this.form.controls.principle.value))));
          for (let index = 1; index <= parseFloat(this.form.controls.contractPeriod.value); index++) {
            date = this.checkDate(date, sumDate, index, date10, date11);
            let installment = {
              'installmentNo': index,
              'dueDate': this.formatDateInput2(date),
              'installmentAmt': (parseFloat(this.form.controls.contractPeriod.value) === index ? this.localeString(parseFloat(this.formatNumber(this.form.controls.remaining.value)) - sum) : this.form.controls.installmentAmt.value)
            };
            sum += parseFloat(this.formatNumber(this.form.controls.installmentAmt.value));
            this.installment.push(installment);

            if (this.form.controls.paymentType.value === '3') {
              date = this.updateDtae(date, date11);
            }
          }
        }
      } else {
        if (this.form.controls.principle.value && this.form.controls.contractPeriod.value
          && this.form.controls.interestRate.value
          && (this.form.controls.daily.value || this.form.controls.paymentDate.value
            || this.form.controls.daily1.value || this.form.controls.daily2.value
            || this.form.controls.daily3.value || this.form.controls.daily4.value)) {
          this.form.controls.installmentAmt.setValue(this.localeString(Math.round(parseFloat(this.formatNumber(this.form.controls.principle.value)) / parseFloat(this.formatNumber(this.form.controls.contractPeriod.value)))));
          let item = 0;
          for (let index = 1; index <= parseFloat(this.form.controls.contractPeriod.value); index++) {
            item = index !== 1 ? item - parseFloat(this.formatNumber(this.form.controls.installmentAmt.value)) : parseFloat(this.formatNumber((this.form.controls.principle.value)));
            let installmentAmt = (Math.round(item * parseFloat(this.form.controls.interestRate.value) / 100) + parseFloat(this.formatNumber(this.form.controls.installmentAmt.value)));
            sum += installmentAmt;
            date = this.checkDate(date, sumDate, index, date10, date11);
            let installment = {
              'installmentNo': index,
              'dueDate': this.formatDateInput2(date),
              'installmentAmt': this.localeString(installmentAmt)
            };
            this.installment.push(installment);

            if (this.form.controls.paymentType.value === '3') {
              date = this.updateDtae(date, date11);
            }
          }
          this.form.controls.total.setValue(this.localeString(Math.round(sum ? sum : 0)));
          this.form.controls.remaining.setValue(this.localeString(Math.round(sum ? sum : 0)));
          this.form.controls.income.setValue(this.localeString((this.form.controls.total.value ? parseFloat(this.formatNumber(this.form.controls.total.value)) : 0) -
            (this.form.controls.principle.value ? parseFloat(this.formatNumber(this.form.controls.principle.value)) : 0)));
        }
      }
    }
  }

  updateDtae(date, date11) {
    date11.setDate(date.getDate());
    date11.setMonth(date.getMonth())
    if (this.formatDateInput(date) === this.formatDateInput(date11) && this.paymentDateSpecificTemp[this.paymentDateSpecificTemp.length - 1].flag) {
      date.setDate(parseInt(this.form.controls.daily1.value));
      date.setMonth(date.getMonth() + 1);

      this.paymentDateSpecificTemp = [];
      this.form.controls.daily1.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily1.value, flag: false }) : null;
      this.form.controls.daily2.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily2.value, flag: false }) : null;
      this.form.controls.daily3.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily3.value, flag: false }) : null;
      this.form.controls.daily4.value ? this.paymentDateSpecificTemp.push({ date: this.form.controls.daily4.value, flag: false }) : null;
    }

    return date;
  }

  checkDate(date: Date, sumDate: any, index: any, date10: Date, date11: Date) {
    if (this.form.controls.paymentType.value === '1') {
      if (index !== 1) {
        sumDate += parseInt(this.form.controls.daily.value);
        date.setDate(date.getDate() + sumDate)
      }
    } else if (this.form.controls.paymentType.value === '2') {
      date.setDate(parseInt(this.form.controls.paymentDate.value));
      if (index === 1) {
        if (new Date(this.form.controls.firstDueDate.value) > date) {
          date.setMonth(date.getMonth() + 1);
        } else {
          date.setMonth(date.getMonth());
        }
      } else {
        date.setMonth(date.getMonth() + 1);
      }
    } else {
      for (let i = 0; i < this.paymentDateSpecificTemp.length; i++) {
        date10.setMonth(date.getMonth());
        console.log(date10.getMonth() + 1)
        if (((date10.getMonth() + 1) === 2) && ((this.paymentDateSpecificTemp.length - 1) === i) && (parseInt(this.paymentDateSpecificTemp[i].date) > 28)) {
          let testDate = new Date(date10.getFullYear(), (date10.getMonth() + 1), 0);
          date10.setDate(testDate.getDate());
        } else {
          date10.setDate(parseInt(this.paymentDateSpecificTemp[i].date));
        }
        if (date.getFullYear() > date10.getFullYear()) {
          date10.setFullYear(date.getFullYear());
          date11.setFullYear(date.getFullYear());
        }
        if (!this.paymentDateSpecificTemp[i].flag && this.formatDateInput(date10) >= this.formatDateInput(date)) {
          if (((date.getMonth() + 1) === 2) && ((this.paymentDateSpecificTemp.length - 1) === i) && parseInt(this.paymentDateSpecificTemp[i].date) > 28) {
            let testDate = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
            date.setDate(testDate.getDate());
          } else {
            date.setDate(parseInt(this.paymentDateSpecificTemp[i].date));
          }
          this.paymentDateSpecificTemp[i].flag = true;
          break;
        } else {
          this.paymentDateSpecificTemp[i].flag = true;
        }
      }
    }
    return date;
  }


  onClickViewInstallmentlDialog() {
    const modalRef = this.modalService.open(ViewInstallmentComponent, {
      windowClass: 'modal-fit-content-view', backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.isCheckStatus = 'addNew';
    modalRef.componentInstance.installment = this.installment;
    modalRef.componentInstance.getData = this.getData();
    modalRef.componentInstance.cfSave = this.cfSave;
    return modalRef;
  }


  formatNumber(value: string) {
    if (value && value !== '') {
      let parts = String(value).replace(/[^-\d.]/g, '').split('.');
      parts[0] = new Big(parts[0].padStart(1, '0')).toString();
      return parts[0];
    }
  }

  localeString(data: number) {
    let points = data.toLocaleString('en-US');
    return points;
  }

  async onPopup(message: string, flag: boolean, status: string) {
    const modalRef = this.modalService.open(PopupComfirmComponent, {
      backdrop: 'static', keyboard: false
    });
    this.setModalRef(modalRef, { message, flag, status });
    return modalRef.result;
  }

  setModalRef(modalRef: any, modalInfo: any) {
    for (const item of Object.keys(modalInfo)) {
      modalRef.componentInstance[item] = modalInfo[item];
    }
    return modalRef;
  }

  getDataApi() {
    this.form.controls.customerName.setValue(this.item['customerName']);
    this.form.controls.description.setValue(this.item['description']);
    this.radioType(this.item['transactionType']);
    this.form.controls.remark.setValue(this.item['remark']);
    this.form.controls.createDate.setValue(this.formatDateInput5(this.item['createDateTemp']));
    this.form.controls.interestType.setValue(this.item['interestType']);
    this.item['interestType'] !== 0 ?
      this.form.controls.interestRate.setValue(this.item['interestRate']) :
      this.form.controls.interestRate1.setValue(this.item['interestRate']);
    this.form.controls.firstDownAmt.setValue(this.item['firstDownAmt'] && this.item['interestRate'] !== 0 ? this.item['firstDownAmt'] : null);

    this.form.controls.paymentType.setValue(this.item['paymentType'] ? this.listData.paymentType.find(even => even.paymentTypeId === this.item['paymentType']).paymentTypeId.toString() : '');
    this.radioPayBy();
    this.form.controls.daily.setValue(this.item['daily'] ? this.item['daily'] : '');
    this.form.controls.paymentDate.setValue(this.item['paymentDate']);
    let test = this.item['paymentDateSpecific'].split(',')
    this.form.controls.daily1.setValue(test && test['0'] ? test['0'] : '');
    this.form.controls.daily2.setValue(test && test['1'] ? test['1'] : '');
    this.form.controls.daily3.setValue(test && test['2'] ? test['2'] : '');
    this.form.controls.daily4.setValue(test && test['3'] ? test['3'] : '');
    this.form.controls.firstDueDate.setValue(this.formatDateInput5(this.item['contractFirstDueDateTemp']));
    this.form.controls.principle.setValue(this.item['principle']);
    this.form.controls.total.setValue(this.item['total']);
    this.form.controls.contractPeriod.setValue(this.item['contractPeriod']);

    this.form.controls.remaining.setValue(this.item['remaining']);
    this.form.controls.installmentAmt.setValue(this.item['installmentAmt']);
    this.change();
    this.form.controls.income.setValue(this.item['income']);
    this.file = this.item['imageUrl'];
  }

  async closeCf() {
    if(this.cfSave) {
      this.close();
      return;
    }
    let popup = await this.onPopup('ปิด ยังไม่ได้บันทึก', true, 'show1');
    if (popup) {
      this.close();
    }
  }

  changeIncome() {
    this.form.controls.income.setValue(this.localeString(parseFloat(this.formatNumber(this.form.controls.income.value))));

  }

  onFileChange(event: any) {
    const files = event.target.files as FileList;
    if (files.length > 0) {
      this.files = event.target.files[0];
      const _file = URL.createObjectURL(files[0]);
      this.file = _file;
      this.resetInput();
    }
  }

  resetInput() {
    const input = document.getElementById('upload') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }

  deleteImage() {
    this.file = '';
    this.resetInput();
  }

  async uploadFile() {
    this.imageUrl = '';
    if (this.files) {
      return new Promise<any>((resolve, reject) => {
        this.service.uploadFile(this.files).subscribe(async resp => {
          if (resp && resp.statusCode === 200) {
            this.imageUrl = resp.imageUrl;
            resolve(true);
          }
        },
          async (errorRes) => {
            if (errorRes.error && errorRes.error.statusCode === 500) {
              await this.onPopup(errorRes.error.message, false, '');
              return;
            }
          });
      });
    }
  }
}
